<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <page-header
          :title="'Company Info'"
          :sub-heading="true"
          :sub-header="'Dashboard / Company Info'"
          :link-name="'Edit Company'"
          :link-url="'/edit-company'"
        >
          <ca-button :color="'danger'">Delete</ca-button>
        </page-header>

        <full-page-content-container>
          <form class="form">
            <div class="form__group">
              <label for="companyName" class="form__label">Company Name</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g Uptimize Tech"
                id="companyName"
                name="name"
                v-model="companyInfo.name"
                v-validate="'required'"
              />
              <span class="form__error">{{ errors.first("name") }}</span>
            </div>

            <div class="form__group">
              <label for="email" class="form__label">E-mail</label>
              <input
                type="email"
                class="form__input"
                placeholder="e.g Johanathandoe@gmail.com"
                id="email"
                name="email"
                v-validate="'required|email'"
                v-model="companyInfo.email"
              />
              <span class="form__error">{{ errors.first("email") }}</span>
            </div>

            <div class="form__group">
              <label for="industry" class="form__label">Industry</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g biopharmabiotechnology"
                id="industry"
                name="industry"
                v-model="companyInfo.industry"
              />
            </div>

            <div class="form__group">
              <label for="country" class="form__label">Country</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g United Kingdom"
                id="country"
                name="country"
                v-model="companyInfo.country"
              />
            </div>

            <div class="form__group">
              <label for="address" class="form__label">Address</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g 1 Somewhere anywhere, London, United Kingdom"
                id="address"
                name="address"
                v-model="companyInfo.address"
              />
            </div>

            <div class="form__group">
              <label for="state" class="form__label">State</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g London"
                id="state"
                name="state"
                v-model="companyInfo.state"
              />
            </div>

            <div class="form__group">
              <label for="city" class="form__label">City</label>
              <input
                type="text"
                class="form__input"
                placeholder="e.g London"
                id="city"
                name="city"
                v-model="companyInfo.city"
              />
            </div>

            <div class="form__group">
              <ca-button @click.native="updateCompanyInfo" :disabled="loading">
                <span class="mr-1">Save Changes</span>
                <span v-if="loading"
                  ><i class="fa fa-spinner fa-spin"></i
                ></span>
              </ca-button>
            </div>
          </form>
        </full-page-content-container>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import axios from "../../../axios";
export default {
  name: "EditCompanyInfo",
  components: { FullPageContentContainer, CaButton, PageHeader },
  data() {
    return {
      loading: false,
      name: "",
      companyInfo: {
        name: "",
        email: "",
        industry: "",
        country: "",
        address: "",
        state: "",
        city: "",
      },
    };
  },
  methods: {
    getCompanyInfo() {
      const loading = this.$vs.loading();
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/show-company/${this.$route.params.id}`
        )
        .then((res) => {
          const data = res.data.data.company;
          // this.name = data.name;
          this.setCompanyInfo(data);
          loading.close();
          // console.log(data);
        })
        .catch((err) => console.log(err));
    },

    updateCompanyInfo() {
      // alert("Button clicked");
      this.loading = true;
      axios
        .post(
          `${this.$store.state.server.requestUri}/admin/update-company`,
          {
            id: this.$route.params.id,
            name: this.companyInfo.name,
            email: this.companyInfo.email,
            industry: this.companyInfo.industry,
            country: this.companyInfo.country,
            address: this.companyInfo.address,
            state: this.companyInfo.state,
            city: this.companyInfo.city,
          }
        )
        .then((res) => {
          const data = res.data.data.company;
          this.setCompanyInfo(data);
          this.loading = false;
          this.$toast("Company updated successfully", {
            type: "success",
            position: "top-center",
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    setCompanyInfo(data) {
      this.companyInfo.name = data.name;
      this.companyInfo.email = data.email;
      this.companyInfo.industry = data.industry;
      this.companyInfo.country = data.country;
      this.companyInfo.address = data.address;
      this.companyInfo.state = data.state;
      this.companyInfo.city = data.city;
    },
  },
  created() {
    this.getCompanyInfo();
  },
};
</script>

<style scoped></style>
